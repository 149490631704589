.modal {
  overflow-y: auto;
  z-index: 7777;
  display: block;
  pointer-events: "none";

  .modal-open {
    overflow: hidden;
  }

  .modal-content {
    border-radius: 0.4em;
    // overflow: hidden;
    margin: 20px 0 30px;
  }

  .modal-header {
    background: $totem-pole;
    color: $white;

    .close {
      color: $white;
      outline: none;
      opacity: 1;
    }
  }
}

.modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
}

.modal-xl {
  max-width: 1200px;
}

.border-dotted {
  border-style: dotted;
}

.modal-pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  .soa {
    padding: 20px;
  }
}

.mfa-card {
  height: 80px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  margin-bottom: 15px;

  .title {
    color: $color-primary;
    font-size: 12px;
    font-weight: bold;
  }

  .description {
    color: #454545;
    font-size: 11px;
    margin-top: -10px;
  }

  &:hover,
  &:focus {
    background: rgba($color-secondary, 0.1);

    .icon-circle {
      background: $color-secondary;
    }
  }

  .icon-circle {
    background-color: #B5B5B7;
    color: white;

    .active {
      background: $color-secondary;
    }

  }
}

.selected-mfa {
  border: 1px solid $color-primary;

  .icon-circle {
    background-color: $color-primary;
  }
}