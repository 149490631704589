.transaction-modal {
  overflow: visible;

  .item-card {
    height: 130px;
    border-radius: 10px;
    color: $dark-blue;
    font-weight: 500;
    // line-height: 35px;
    background-color: #f1f0f1;
    border: 2px solid transparent;

    @include media-breakpoint-down(sm) {
      height: 100% !important;
      padding: 10px;
    }

    &:hover,
    &:focus {
      border: 2px solid $color-primary;
    }
  }

  .item-card-active {
    border: 2px solid $color-primary;
    height: 130px;
    border-radius: 10px;
    color: $dark-blue;
    font-weight: 500;
    line-height: 35px;
    background-color: #f1f0f1;
  }

  .transaction-head-icon {
    height: 27px;
    width: auto;
  }

  .transaction-icon {
    height: auto;
    width: 70px;
  }

  .transaction-description {
    @media (max-width: 1200px) {
      font-size: 14px !important;
    }
  }
}

.transaction-table-item {
  th {
    padding: 0px;
  }

  td {
    padding: 0px;
  }
}

.e-bills-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.e-bills-title {
  span {
    font-size: 30px;
    font-weight: bold;
    margin-left: 15px;
  }
}

.e-bills-button {
  width: 200px;

  button {
    width: 100%;
    padding: 10px;
  }
}

.calendar-mobile {
  .rbc-event-content {
    white-space: normal !important;
    font-size: 5px !important;

    @include media-breakpoint-down(sm) {
      font-size: 3px !important;
    }
  }
}

.calendar-web {
  .rbc-event-content {
    white-space: normal !important;
    font-size: 8px !important;
  }
}

.appointment-label {
  font-size: 12px;
  color: white;

  @include media-breakpoint-down(sm) {
    font-size: 8px !important;
  }
}

.frame {
  overflow-x: auto;
  border: 1px solid black;
  height: 100%;
  width: 100%;
  line-height: 1em;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: $color-primary;
}

.tab-list-business {
  display: flex;

  li {
    list-style-type: none;
  }
}

.renew-div .react-tabs__tab {
  font: normal normal normal 12px/18px Poppins !important;
  color: $color-primary;
  background: $alice-blue;
  margin-right: 10px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  border-radius: 5px;

  &--selected {
    background: $color-primary;
    color: #fff;
    font-weight: bold;
    width: 200px;
  }
}

.renew-div-mobile .react-tabs__tab {
  font: normal normal normal 12px/18px Poppins !important;
  color: $color-primary !important;
  background: $alice-blue;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  border-radius: 5px;

  &--selected {
    color: #fff !important;
    background: $color-primary;
    outline: none !important;
    padding: 16px 10px;
    border-radius: 5px;
  }
}

.tab-list-business-mobile {
  width: 100%;
  display: flex;
  // overflow-y: auto;
  white-space: nowrap;
  border-right: unset;
  margin-left: -30px;
  padding: 10px;

  li {
    list-style-type: none;
  }
}

.tab-list-profile-mobile {
  display: flex;
  width: 250px;
  // overflow-y: auto;
  white-space: nowrap;
  border-right: unset;
  margin-left: 20px;
  padding: 10px;

  li {
    list-style-type: none;
  }
}

.steps-details-mobile {
  margin-right: -30px !important;
}

.inquiry-modal {
  overflow: visible;

  .item-card {
    height: 130px;
    border-radius: 10px;
    color: $dark-blue;
    font-weight: 500;
    // line-height: 35px;
    background-color: #f1f0f1;
    border: 2px solid transparent;

    @include media-breakpoint-down(sm) {
      height: 100% !important;
      padding: 10px;
    }

    // &:hover,
    // &:focus {
    //   border: 2px solid $color-primary;
    // }
  }

  .item-card-active {
    border: 2px solid $color-primary;
    height: 130px;
    border-radius: 10px;
    color: $dark-blue;
    font-weight: 500;
    line-height: 35px;
    background-color: #f1f0f1;
  }

  .transaction-head-icon {
    height: 27px;
    width: auto;
  }

  .transaction-icon {
    height: auto;
    width: 70px;
  }

  .transaction-description {
    @media (max-width: 1200px) {
      font-size: 14px !important;
    }
  }
}

.table-header {
  background: #F2F7FF;
  color: $color-primary !important;
  font-size: 12px;
  border: 0;
}

.table-bg-laspinas {
  background: #F1FEF7;
  color: #0A8A45 !important;
  font-size: 12px;
  border: 0;
}

.left-side-title {
  color: $color-primary;
  font-size: 20px/30px;
  font-weight: 600;
}

.right-side-title {
  color: #000;
  font-size: 20px/30px;
  font-weight: 600;
}